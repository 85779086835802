body.activeModal {
    overflow-y: hidden;
}

.btnModal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal,
.overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
}

.overlay {
    background: rgba(49, 49, 49, 0.8);
}

.modalContent {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background-color: #323f5f;
    padding: 14px 28px;
    max-width: 1121px;
    /* min-width: 58%; */
    z-index: 2;
    color: #FFF;
    text-align: center;
    padding: 25px;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
    /* background-color: #2a576e; */
}

.modalMainTitle {
    font-size: 40px;
    margin-top: 61px;
    margin-bottom: 43px;
}

.modalTitle {
    font-size: 21px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.22;
}

.modalDate {
    font-size: 21px;
    line-height: 1.22;
    margin-bottom: 59px;
}

.modalCheckbox {
    color: #FFF;
    margin-top: 0px;
    margin-bottom: 0px;
}

.closeModal {
    position: absolute;
    top: -25px;
    right: 46%;
    padding: 5px 7px;
    color: #ffffff;
    background-color: black;
    border: none;
    border-radius: 25px;
    width: 42px;
    height: 42px;
    cursor: pointer;

}

.DeclareBtn {
    color: #fff;
    /* margin-top: 50px; */
    letter-spacing: 1.82px;
    font-size: 11px !important;
    /* padding: 0 19px !important; */
    height: 46px;
    border-radius: 14px !important;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 0;
    position: absolute;
}

.DeclareBtn span {
    font-family: "Book", Arial, serif !important;
    font-size: 11px;
    letter-spacing: 1.82px;
    color: #FFF;
}

.DeclareBtn:hover,
.DeclareBtn:focus,
.DeclareBtn button:hover,
.DeclareBtn button:focus,
.DangerBtn:hover,
.DangerBtn:focus,
.DangerBtn button:hover,
.DangerBtn button:focus,
.PostBtn:hover,
.PostBtn:focus,
.PostBtn button:hover,
.PostBtn button:focus {
    /* box-shadow: 0 0.1em 0.5em -0.4em white; */
    /* transform: translateY(-0.1em); */
    /* border-color: #fff !important; */
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    transition: .4s;
}

.DangerBtn {
    height: 46px;
    flex-grow: 0;
    border-radius: 14px !important;
    background-color: #ff0000;
    margin-top: 20px;
    letter-spacing: 1.82px;
    color: #fff;
    font-size: 11px !important;
    padding: 0 19px !important;
}

.DangerBtn span {
    font-family: "Book", Arial, serif !important;
    font-size: 11px;
    letter-spacing: 1.82px;
    color: #FFF;
}

.DangerBtn:hover,
.DangerBtn:focus {
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
    transition: .4s;
    border-color: transparent !important;
}



.carousel {
    padding: 44px 0px 110px 10vw;
    background-color: #fff;
    width: 100%;
}

.carousel h2 {
    color: #323f5f;
    font-family: inherit;
    font-size: 1.8em;
    font-style: normal;
    font-weight: 600;
    padding-bottom: 12px;
    text-align: left;
}

.carouselslider {
    /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.86) 0%, #FFFFFF 53.65%); */
    margin: 20px;
    margin-left: 0px;
    width: 100% !important;
    position: relative;
}

.carouselslider a {
    color: #0f0f0f;
    text-decoration: none !important;

}

.carouselimg {
    width: 100%;
    height: 290px;
    position: relative;
    overflow: hidden;

}

.carouselimg img {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    object-fit: cover;
}

.carouselslider:hover div{
  /* filter: blur(4px);
  opacity: 0.8; */
  padding-bottom: 30px;
  transition: .5s;
}

/* .carouselslider:hover .carouselmore {
  display: block;
  filter: blur(0px) !important;
  opacity: 1;
} */

.carouselslider:hover h3 {
    height: auto;
}

.carouselslider h3,
.carouselslider p img {
    padding: 10px;
    padding-bottom: 0px;
}

.carouselslider p {
    color: #323F5F !important;
    position: relative;
}

.carouselslider p img {
    float: left;
    display: inline;
}

.carouselslider span {
    font-size: 18px;
    position: relative;
    color: #D55E2D;
    font-family: inherit;
    padding: 15px 20px;
    font-weight: 600;
}

.carouselslider div {
    padding: 8px 0px 8px 0;
}

.carouselslider .carouselmore {
    display: none
}

.carouselslider a {
    font-size: 18px;
    position: relative;
    color: #E0C084 !important;
    font-family: inherit;
    font-weight: 600;
    padding-left: 10px;
}

.carouselslider a:hover {
    color: #D55E2D !important;
}

.carouselslider h3 {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    line-height: 20px;
    color: #323F5F;
    text-align: left;
    font-family: inherit;
    padding: 5px 20px;
    height: 62px;
    display: flex;
    align-content: center;
    align-items: center;
    min-height: 77px;
    letter-spacing: 0;
}

.carouselslider p {
    font-size: 15px;
    height: 25px;
    overflow: hidden;
}

.carousel h4 {
    display: inline;
    float: right;
    position: absolute;
    right: 10vw;
    font-size: 18px;
}

.carousel h4 a:hover {
    color: #323F5F !important;
}

.carousel h4 a {
    text-decoration: none;
    color: #D55E2D !important;
}

.carousel h4 span {
    color: #D55E2D !important;
    margin-left: 10px;
    background: #F9F2E6 !important;
    border-radius: 50px;
    font-size: 1em;
    width: 35px;
    height: 35px;
    line-height: 35px;
}

.carouseldesc {
    position: absolute !important;
    height: auto;
    overflow: hidden;
    position: relative;
    bottom: -30px;
    background: hsla(0, 0%, 100%, .86) !important;
    width: 100%;
    z-index: 0;
}


@media only screen and (max-width: 1281px) {
    .carouseldesc {
        bottom: 30px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
    .modalMainTitle {
        font-size: 34px;
    }

    .modalContent {
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background-color: #2a576e;
        padding: 14px 28px;
        min-width: 80%;
        z-index: 2;
        color: #FFF;
        text-align: center;
        /* padding: 13px 21px 45px 17px; */
        box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
        background-color: #2a576e;
    }

    .modalCheckbox {
        max-width: 64%;
    }
}

@media only screen and (max-width: 600px) {
    .modalMainTitle {
        font-size: 34px;
    }

    .modalContent {
        position: absolute;
        top: 53%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background-color: #2a576e;
        padding: 14px 28px;
        min-width: 96%;
        z-index: 2;
        color: #FFF;
        text-align: center;
        /* padding: 13px 21px 45px 17px; */
        box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
        background-color: #2a576e;
    }

    .modalContent>div {
        width: 100% !important;
    }

    .modalCheckbox {
        max-width: 64%;
    }

    .closeModal {
        position: absolute;
        top: -25px;
        right: 45%;
        padding: 5px 7px;
        color: #ffffff;
        background-color: black;
        border: none;
        border-radius: 25px;
        width: 42px;
        height: 42px;
        cursor: pointer;
        font-size: 1rem;
    }
}