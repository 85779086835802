.footer_upper {
  background: #323F5F;
  width: 100%;
  color: white;
  padding: 30px 150px;
  height: 200px;
}


.footerText_ {
  padding: 60px 0;
  text-align: right;
}

.footerText {
  padding: 60px 0;
  text-align: left;
}

.footer_lower span {
  padding-left: 10px;
  line-height: 80px;
  font-size: 16px;
}

.footer_bold {
  font-size: 17px !important;
  font-weight: 600;
  padding-left: 40px;
}

.footer_bold_phone {
  font-size: 17px !important;
  font-weight: 600;
  padding-left: 100px;
}

.footer_bold_email {
  font-size: 17px !important;
  font-weight: 600;
  padding-left: 0px;
}


.footer_lower img {
  padding-right: 50px;
  float: left;
}

.footer_lower_1 {
  background: #F9F2E6;
  color: #23252A;
  padding: 40px 100px;
}

.footer_lower_2 {
  background: #F9F2E6;
  color: #23252A;
  padding: 72px 30px;
}

.footer_lower2 {
  background: #F9F2E6;
  width: 100%;
  color: #23252A;
  padding: 20px 280px;
  text-align: center;

}

.footer_lower2 img {
  max-width: 40%;
}

.footer_menu1 a,
.footer_menu2 a {
  color: #E0C084;
  text-decoration: none !important;
}

.footer_menu1 a {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 5rem;
}

.footer_menu2 a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
}

.footer_menu1,
.footer_menu2 {
  width: 100%;
  display: block;
  float: left;
  clear: both;
  line-height: 3rem;
  text-align: center;
}

.footer_menu2 {
  margin-left: -60px;

}

.footer_menu1 li,
.footer_menu2 li {
  display: inline;
  padding: 10px 36px;
}

.footer_menu1 {
  border-bottom: 2px solid #D55E2D;
  margin-left: -50px;
}

.stylesA {
  display: block;

}

.footer_lower_1 {
  color: #323F5F;
  font-size: 20px;
  margin-top: 10px;
}

.logotxt {
  color: #323F5F;
  font-size: 20px;
  margin-top: 10px;
}

.logotxt strong {
  color: #BBA15A;
  font-size: 20px;
  letter-spacing: 8px;
  position: absolute;
  top: 65px;
}

.footer_lower_1 b {
  position: absolute;
  top: 90px;
}

.footer_lower_1 span {
  border-left: 2px solid #323F5F;
  font-family: 'Source Serif 4';
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.footer_lower_11 {
  display: flex;
}

.footer_lower_11 img {
  padding-top: 80px;
  width: 80%;
  height: auto;
}

.socialSidebar {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.icon {
  color: #323f5f;
  font-size: 1.5rem;
}

.credits{
  text-align: center;
  padding: 2rem 0;
  background-color: #323F5F;
  color: #E0C084;
}

.credits a{
  font-weight: bold;
  color: #E0C084;
}

.credits a:hover{
  text-decoration: underline;
  cursor: pointer;
  color: #E0C084;
}

@media only screen and (max-width: 780px) {

  .footer_menu1 li,
  .footer_menu2 li {
    display: block;
    padding: 10px 40px;
  }

  .footer_lower2 img {
    max-width: 90%;
  }

  .footer_upper {
    padding: 6px 0px;
    height: 300px;
  }

  .footer_menu1 li,
  .footer_menu2 li {
    display: inline-block;
    padding: 2px 16px 0px 16px;

  }

  .footer_menu1,
  .footer_menu2 {
    float: inherit;
    line-height: 2.5rem;
    margin-left: -20px;
    padding-bottom: 20px;
  }

  .footer_lower {
    padding: 10px 8px;
  }

  .footer_lower2 {
    padding: 13px 1px;
    padding-right: 0px;
  }

  .footer_menu1 a {
    line-height: 3rem;
  }

  .footer_lower_1 {
    padding: 20px 100px;
  }

  .footer_lower_2 {
    padding: 6px 14px;
  }

  .footer_bold,
  .footer_bold_phone,
  .footer_bold_email {
    padding-left: 1px;
  }

  .footerText_ {
    padding: 2px 0px 0px 10px;
    text-align: left;
  }

  .footerText {
    padding: 2px 0px 0px 10px;
    text-align: left;
  }

  .footer_lower_11 img {
    padding-top: 20px;
    width: 60% !important;
    height: auto;
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: 1220px) {
  .footer_lower span {
    display: block;
    line-height: 22px;
  }

  .footer_menu1 li,
  .footer_menu2 li {
    padding: 2px 22px;
  }
}

@media only screen and (max-width: 991px) {
  .footer_lower_11 img {
    padding-top: 20px;
    width: 40% !important;
    height: auto;
    margin-bottom: 20px;
  }
  .footer_lower_11 {
    justify-content: center;
  }
}