.search {
    position: fixed !important;
    inset: 0 0 0 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-inline: 15px;
    background-color: var(--dark);
    z-index: 999;
    max-height: 100vh;
    overflow-y: auto;
    opacity: 1;
    animation: .5s fade-in ease forwards;
    /* background-color:rgb(99, 98, 98); */
    background-color: #313e5edb;
    height: 100vh;
    z-index: 999999 !important;
}

.closeButton {
    position: fixed;
    top: 46px;
    right: 40px;
    cursor: pointer;
}


.searchWrapper {
    width: 100%;
    max-width: 1000px;
    padding-block: 90px;
}

.searchContent {
    margin-bottom: 60px;
}

.searchContent input::placeholder {
    color: #d1d3d3
}

.searchContent h1 {
    font-size: 3rem;
    line-height: 1.25;
    font-weight: 500;
    margin-bottom: 52px;
    color: #e9e9e9;
}


.searchContent input {
    background: transparent;
    width: calc(100% - 8px);
    border: 0;
    color: white;
    border-bottom: 1px solid #ede8e9;
    outline: 0;
    font-size: 1.625rem;
    line-height: 1.54;
}

.result_message {
    margin-bottom: 80px;
    font-size: 2.45rem;
    line-height: 1.11;
    font-weight: 500;
    color: white;
}

.results_list {
    color: white;
    text-align: justify;
    font-size: 20px;
}

.results_list h1,
.results_list h2 {
    color: white;
}

.results_list h1 {
    font-size: 24px;
    text-align: left;
}

.results_list h1:hover {
    text-decoration: underline;
}

.results_list h2 {
    font-size: 24px;
    text-decoration: none;
    align-self: start;
}


.result_item {
    border: 1px solid white;
    padding: 10px 15px;
    margin: 20px 0;
    /* border-radius: 16px; */
    border: 0;
    /* background-color: rgb(94 93 93); */
    border-bottom: 1px solid #FFF;
    /* background-color: #313E5E; */
}

section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: -0.5em;
}

.spinner span{
    font-size: 50px !important;
    color: #FFF !important;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@media screen and (max-width: 680px) {
    .searchContent h1 {
        font-size: 2rem;
    }

    .searchContent input {
        font-size: 1.1rem;
    }

    .result_message {
        font-size: 1.5rem;
    }

    .searchContent {
        margin-bottom: 50px;
    }

    .results_list h1 {
        font-size: 26px;
        line-height: 1.8rem;
    }

    .results_list {
        font-size: 18px;
        line-height: 1.6rem;
    }



}