.calendarContainer__container {
  margin: 60px 0px 40px 0px;
  font-size: 32px;
  line-height: 1.33;
  font-weight: 500;
  text-align: center;
}


.calendarContent__overlay__Grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 305px));
  grid-gap: 20px;
}

.calendarContent__container {
  padding-top: 0px;
  padding-left: 1px;
  border: 1px solid grey;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.calendarContent__overlay__List>div:not(:last-of-type) {
  margin-bottom: 20px;
}

.mobapp {
  float: left;
  display: block;
  position: relative;
  width: 100%;
  clear: both;
  margin: 0 auto;
  background-color: #fff;
}

.mobapp h3 {
  color: #323f5f;
  font-family: inherit;
  font-size: 1.8em;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 20px;
  text-align: left;
}

.btn {
  background: #F9F2E6;
  border: 1px solid #D55E2D;
  border-radius: 40px;
  padding: 0px 20px;
  margin-top: 20px;
  position: relative;
  float: left;
}

a.btn {
  color: #D55E2D;
  font-size: 18px;
}

.btn:hover {
  background: #D55E2D;
  border: 1px solid #F9F2E6;
  color: #F9F2E6;
}

.mobapp_inner {
  width: 90%;
  position: relative;
  margin: 0 auto;
  color: #323F5F;
  margin-right: 0px;
  display: grid;
  grid-template-columns: 38% 11% 40%;
}

.part2a {
  background: rgba(255, 255, 255, 0.15);
  /* background-color: #f1f1f1ed; */
  /* Note: backdrop-filter has minimal browser support */
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  width: 6vw;
  height: 138px;
  backdrop-filter: blur(8px);

  /* background: linear-gradient(to left, rgb(255 255 255 / 93%) 78px, rgb(255 255 255) 240px, rgb(255, 255, 255) 290px) no-repeat; */

  /* background-color: #ccc; */

  border-radius: 0px;
  /* backdrop-filter: blur(230px); */
  height: 100%;
  left: 8vw;
  position: relative;
  z-index: 1000;
}



.part1 {
  float: left;
  display: inline;
  position: relative;
  /*width:40%;*/
  padding-top: 10px;
  z-index: 1000;
}

.part2 {
  float: left;
  display: inline;
  position: relative;
  /*width:60%;*/
  padding-top: 0px;
  z-index: 999;
}

.part2 a {
  float: right;
}

.part2 img {
  width: 100%;
  margin: 0 auto;
  /* padding-top: 24px; */
  height: 417px;
  /* max-height: 200px; */
  object-fit: contain;
  object-position: top;
  border-top-right-radius: 150px;
  float: right;
}



.part2Open img {
  width: 100%;
  margin: 0 auto;
  padding-top: 24px;

  max-height: none;
  object-fit: fil;
  object-position: top;
  border-top-right-radius: 150px;
}

.part1 img.mb1 {
  margin-top: 30px;
  height: 100px;
  margin-right: 30px;
}

.mbic {
  float: left;
  display: block;
}

.mbic1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.dividerBlock {
  margin: 0px auto;
  width: 80%;
  border-bottom: 1.5px solid #D55E2D;
}

.divider {
  z-index: 1;
  height: 40px;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  display: block;
  float: left;
}

.more {
  color: #D55E2D !important;
  border: none;
  background: none;
}

.top_banner_txt {
  width: 45%;
  height: 650px;
  background: rgba(249, 242, 230, 0.7);
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 200px 0px;
}

.top_banner_txt div {
  padding: 90px;
  position: absolute;
  left: 0;
  top: 20%;
}

.top_banner_txt h2 {
  font-size: 35px;
}

.top_banner_txt1 {
  color: black;
  font-size: 28px;
  width: 60%;
  margin: 0 auto;
  padding-top: 100px;
  line-height: 45px;
}

.top_banner_txt1 a {
  color: #D55E2D !important;
  font-size: 20px !important;
  background: #F9F2E6;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt1 a:hover {
  color: #F9F2E6 !important;
  font-size: 20px !important;
  background: #D55E2D;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt h2 {
  color: black;
  text-align: left;
  width: 60%;
  /* margin: 0 auto; */
  padding-top: 20px;
  font-size: 42px;
}

.top_banner_txt2 {
  color: black;
  font-size: 18px;
}

.top_banner_img {
  position: absolute;
  top: -150px;
  z-index: -999;
  width: 100%;
  height: 531px;
  right: 0px;
  left: 100%;
  /* max-height: 580px; */
  overflow: hidden;
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  border-radius: 0px 0px 0px 200px;
}

.top_banner_img img {
  height: auto;
  width: auto;
  float: none;
  top: -450px;
}

.top_banner_txt ul li {
  list-style-type: disc;
  font-size: 30px;
  line-height: 32px;
  margin: 20px 0;
}

.filters {
  width: 90%;
  position: relative;
  margin: 0 auto;
  color: #323F5F;
  margin-right: 0px;
  /* display: block !important; */
  /* grid-template-columns: 90%; */
  margin-top: 25px;
  margin-bottom: 10px;
  row-gap: 10px;
}

.filters div {
  width: 73%;
  margin-right: 10px;
}

.filters li:nth-child(1) {
  /* float: left; */
}

/* .filters li:not(:first-child) {
  float: right;
} */

.filters li:nth-child(3) {
  display: none;
}

.filters>ul>li {
  float: left;
}

.filters>ul>li:nth-child(2) {
  float: right;
  /* display: flex; */
}

.filters>ul>li:nth-child(3) {
  float: right;
  /* display: flex; */
}


.filters>ul>li:nth-child(4) {
  float: right !important;
  /* display: flex; */
}

.type>div,
.month>div,
.days>div {
  background-color: #F9F2E6 !important;
  color: #D55E2D;
  border: 2px solid #E0C084;
  border-radius: 40px;
  font-weight: 600;
}

.type>div:hover,
.month>div:hover,
.days>div:hover {
  border-color: #D55E2D !important;
}

.top_banner_txt span {
  position: relative;
  float: left;
  margin: 10px;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #323F5F;
  padding-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

@media only screen and (max-width: 667px) {
  .calendarContent__overlay__Grid {
    justify-content: center;
  }
}

.calendarCard {
  position: relative;
  width: 100%;
  height: 530px;
  font-family: var(--fontDescription);
  background-color: var(--mainBackgroundColor);
  cursor: pointer;
}

.calendarCard__imgBorder {
  width: 100%;
  height: 220px;
  overflow: hidden;
}

.calendarCard__imgBorder img {
  width: 100%;
  height: 100%;
  will-change: transform;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
}

.calendarCard__imgBorder2 img {
  width: 100%;
  height: auto;
  will-change: transform;
  object-fit: cover;
}

.calendarCard__legend {
  position: absolute;
  padding: 6px 13px 6px;
  font-size: 14px;
  line-height: 1.45;
  border-radius: 12px;
  background-color: rgba(239, 230, 223, 0.6);
  z-index: 1;
  inset: 1rem auto auto 1rem;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.calendarCard__contentBorder {
  position: relative;
  height: calc(100% - 220px - 25px - 25px);
  margin: 16px;
  overflow: hidden;
}

.calendarCard__contentBorder h1 {
  display: block;
  display: -webkit-box;
  height: calc(21px * 1.33 * 4);
  padding: 0;
  font-family: var(--fontMain);
  font-size: 16px;
  line-height: 1.33;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.calendarCard__contentBorder>div:first-of-type {
  display: none;
}

.calendarCard__contentBorder>div:nth-of-type(2) {
  position: absolute;
  font-size: 14px;
  line-height: 1.07;
  letter-spacing: -0.35px;
  white-space: nowrap;
  inset: auto auto 0 0;
}

.calendarCardList {
  display: flex;
  height: 229px;
}

.calendarCardList .calendarCard__imgBorder {
  flex-shrink: 0;
  width: auto;
  height: 229px;
}

.calendarCardList .calendarCard__imgBorder img {
  height: 100%;
  aspect-ratio: 1/1;
}

.calendarCardList .calendarCard__contentBorder {
  min-width: 190px;
  height: calc(100% - 25px - 25px);
}

.calendarCardList .calendarCard__contentBorder h1 {
  height: calc(21px * 1.33 * 2);
  margin-bottom: 10px;
  -webkit-line-clamp: 2;
}

.calendarCardList .calendarCard__contentBorder>div:first-of-type {
  display: block;
  display: -webkit-box;
  height: calc(15px * 1.33 * 4);
  font-family: var(--fontContent);
  font-size: 15px;
  line-height: 1.33;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}


.container {
  padding: 0px 20px;
  text-align: justify;
  font-size: 16px;
  line-height: 1.3;
}

.type,
.month,
.days {
  width: 100% !important;
  padding: 4px;
  color: grey;
  margin-right: 25px !important;
}

@media (hover: hover) {
  .calendarContent__container:hover {
    outline: 1px solid #F98560;
    background-color: #FFF9F7;
    ;
  }

  .calendarCard :hover img {
    transform: scale(1.1);
  }

  .calendarCard__imgBorder:hover {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    opacity: 0.7;
  }
}


@media only screen and (max-width: 700px) {
  .calendarCardList .calendarCard__legend {
    inset: 1rem auto auto 0;
  }

  .calendarContainer__container {
    margin: 20px 0px 0px 0px;
    font-size: 27px;
  }

  .type,
  .month,
  .days {
    width: 90%;
    margin-left: 10px;
  }

  .calendarContent__overlay__Grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 375px));
    margin: 10px;
  }

  .calendarCard__contentBorder h1 {
    height: calc(21px * 1.33 * 2);
    font-size: 15px;
  }

  .calendarCard {
    height: 370px;
  }

  .calendarCardList .calendarCard__imgBorder {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    width: 150px;
  }

  .calendarCardList .calendarCard__imgBorder img {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }

  .calendarCardList .calendarCard__contentBorder {
    flex-shrink: 3;
  }

  .container {
    padding: 20px 0px;
  }

  .top_banner_txt div {
    padding: 50px 5vw 90px 40px;
  }
  .top_banner_txt {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
    backdrop-filter: blur(25px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 0px 0px 200px 0px;
  }
  .top_banner {
    width: 100%;
    background-image: url("");
    background-position: bottom -13px right 0px;
    background-repeat: round;
    height: 200px;
  }

  .top_banner img {
    width: 100%;
    top: 0px;
    position: relative;
    opacity: 0.6;
    height: 222px;

  }

  .top_banner_txt h2 {
    font-size: 22px;
    margin: 20px 30px 30px 18px;
    line-height: 1.8rem;
    font-weight: bold;
    width: 100%;
  }

  .top_banner_txt {
    position: relative;
    top: -200px;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }

  .top_banner_txt div {
    padding: 90px 0;
    position: absolute;
    left: 0;
    top: 20%;
  }

  .top_banner_txt1 {
    color: #4A4A4A;
    font-size: 24px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt2 {
    color: black;
    font-size: 22px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt span {
    margin: 135px 35px 15px 45px;
  }

   .filters>div {
    max-width: 100%;
    width: 95% !important;
  }

}

@media screen and (min-width: 681px) and (max-width: 1180px) {

  

  .top_banner_txt h2 {
    width: 100%;
  }
}

@media only screen and (max-width: 1080px) {
  .mobapp_inner {
    grid-template-columns: 89%;
  }

  .part2 a {
    float: left;
    margin: 4rem auto 0 auto;
    width: 100%;
  }

  .top_banner_txt div {
    /* padding: 40px 90px; */
    position: absolute;
    left: 0;
    top: 20%;
  }

  .filters>div {
    max-width: 100%;
    width: 85%;
  }
}

@media only screen and (max-width: 400px) {
  .calendarCard__contentBorder>div:nth-of-type(2) span:first-of-type {
    display: none;
  }

  .calendarCard__contentBorder>div:nth-of-type(2) span:last-of-type:before {
    content: '~ '
  }

  .calendarCardList .calendarCard__contentBorder {
    min-width: 120px;
  }

  .filters>div {
    max-width: 100%;
    width: 50% !important;
    margin: 0 auto;
  }

  .filters {
    width: 100%;
    flex-direction: column !important;
    margin-bottom: 0px;
  }
}


.calendarGrid__skeleton :empty {
  width: 100%;
  height: 449px;
  background: linear-gradient(300deg, transparent 0%, transparent 40%, hsla(0, 0%, 100%, 0) 40%, hsla(0, 0%, 100%, .5) 48%, hsla(0, 0%, 100%, .5) 52%, hsla(0, 0%, 100%, 0) 60%, transparent 60%),
    linear-gradient(#EEE, #EEE),
    linear-gradient(#EEE, #EEE),
    linear-gradient(#EEE, #EEE),
    linear-gradient(#EEE, #EEE);
  background-color: var(--mainBackgroundColor);


  background-repeat: no-repeat;
  background-position: -449px 0, 0 0, 25px 250px, 25px calc(250px + (21px * 1.33)), 25px calc(100% - 25px);

  background-size: 100% 100%, 100% 220px, calc(100% - 50px) 21px, calc(60% - 50px) 21px, 180px calc(14px * 1.07);
  cursor: progress;

  animation: loadingGrid 1.5s infinite;
}


@keyframes loadingGrid {
  to {
    background-position: 449px 0, 0 0, 25px 250px, 25px calc(250px + (21px * 1.33)), 25px calc(100% - 25px);
  }
}


.calendarList__skeleton :empty {
  width: 100%;
  height: 229px;
  background: linear-gradient(300deg, transparent 0%, transparent 40%, hsla(0, 0%, 100%, 0) 40%, hsla(0, 0%, 100%, .5) 48%, hsla(0, 0%, 100%, .5) 52%, hsla(0, 0%, 100%, 0) 60%, transparent 60%),
    linear-gradient(#EEE, #EEE),
    linear-gradient(#EEE, #EEE),
    linear-gradient(#EEE, #EEE),
    linear-gradient(#EEE, #EEE),
    linear-gradient(#EEE, #EEE);
  background-color: var(--mainBackgroundColor);

  background-repeat: no-repeat;


  background-position: -1000px 0, 0 0, calc(229px + 25px) 32px, calc(229px + 25px) calc(32px + (21px * 1.33)), calc(229px + 25px) calc(21px * 1.33 * 2 + 25px + 10px), calc(229px + 25px) calc(100% - 25px);

  background-size: 100% 100%, 229px 229px, calc(100% - 50px - 229px) 21px, calc(60% - 50px - 229px) 21px, calc(100% - 50px - 229px) calc(15px * 1.33 * 4), 180px calc(14px * 1.07);

  cursor: progress;
  animation: loadingSkeleton 1.5s infinite;
}

@keyframes loadingSkeleton {
  to {
    background-position: 1000px 0, 0 0, calc(229px + 25px) 32px, calc(229px + 25px) calc(32px + (21px * 1.33)), calc(229px + 25px) calc(21px * 1.33 * 2 + 25px + 10px), calc(229px + 25px) calc(100% - 25px);
  }
}


.priceSmall {
  font-size: 14px;
  font-weight: 600;
  color: #053605;
}