.top_banner {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.29);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 0px;
  ;
  background-repeat: revert;
  background-size: cover;
  height: 431px;
}

.top_banner img {
  width: 100%;
  position: relative;
}

.top_banner_txt {
  top: 0px;
  text-align: left;
  margin-bottom: 0.4em;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px;
}

.top_banner_txt h2 {
  font-size: 35px;
}

.top_banner_txt1 {
  color: black;
  font-size: 28px;
  width: 45%;
  margin: 0 auto;
  padding-top: 140px;
  line-height: 28px;
}

.top_banner_txt h2 {
  color: black;
  text-align: left;
  width: 60%;
  margin: 10px auto;
  padding-top: 0px;
  font-size: 42px;
  margin-left: 15.9vw;
  line-height: 1.2;
}

.top_banner_txt2 {
  color: black;
  font-size: 18px;
}

.top_banner_txt1 img {
  width: 28px;
  height: auto;
  position: relative;
  float: left;
  display: inline;
  margin-right: 10px;
  margin-top: 20px;
}

.top_banner_txt ul li {
  list-style-type: disc;
  font-size: 30px;
  line-height: 32px;
  margin: 20px 0;
}

.top_banner_img {
  position: absolute;
  top: -150px;
  z-index: -999;
  width: 100%;
  height: 531px;
  right: 0px;
  left: 100%;
  /* max-height: 580px; */
  overflow: hidden;
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  border-radius: 0px 0px 0px 200px;
}

.top_banner_img img {
  height: auto;
  width: auto;
  float: none;
  top: -450px;
  left: -100%;
}

.p {
  text-align: justify;
  font-size: 18px;
  padding: 0px 0px 0px 10px;
}

.gallerycat {
  width: 100%;
  float: left;
  height: auto;
  display: block;
}

/* .bannerImage {
  border-bottom-right-radius: 140px 140px;
} */

.loading {
  font-size: 24px;
  font-weight: 600;
  height: 700px;
  margin: 20px 0;
}

.gallery {
  color: black !important;
  font-weight: normal !important
}

.gallery1 {
  color: #323F5F !important;
  font-weight: 900 !important
}

.top_cat {
  width: 80%;
  margin: 0 auto;
  padding-top: 10px;
  /* padding-bottom: 30px; */
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  /* padding: 10px; */
}

.categoriesimg {
  width: 100%;
  margin: 0 auto;
  float: left;
  position: relative;
  display: inline;
  padding: 10px;
  height: 260px;
  overflow: hidden;

  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 0;
}

.categoriesimggal {
  width: 100%;
  margin: 0 auto;
  float: left;
  position: relative;
  display: inline;
  padding: 10px;
  height: 350px;
  overflow: hidden;
}

.top_cat_gal {
  width: 80%;
  margin: 0 auto;
  float: left;
  position: relative;
  overflow: hidden;
}

.categoriesimggal img {
  width: 100%;
  height: 450px;
  overflow: hidden;
}

.categoriesimggal img {
  width: 100%;
  height: 450px;
  overflow: hidden;
}

.categoriesimg img {
  width: 100%;
  float: left;
  position: relative;
  display: block;
  /* padding: 5px; */
  overflow: hidden;
  height: 260px;
  object-fit: cover;
}

.categoriesimg span {
  float: right;
  position: absolute;
  display: block;
  padding: 0px;
  top: 0px;
  right: 0px;
  font-size: 24px;
  color: #323F5F;
  width: 100%;
  height: 100%;
  /* Note: backdrop-filter has minimal browser support */
  background: rgba(255, 255, 255, 0.55);
  padding: 20px;
  display: none;
  justify-content: center;
  align-items: center;
}

.desc {
  border-left: 2px solid #323f5f;
  margin: 40px 20px;
}

.desc p {
  font-family: BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  color: black !important;

  padding: 0 0 0 4vw;
}

.p {
  /* height: 75px!important; */
  overflow: hidden;
  font-size: 15px;
  line-height: 18px;

  /* margin-bottom: 20px; */
  font-weight: normal;
  /* border-left: 3px solid #323f5f;
  margin: 20px; */
}

.p1 {
  color: rgb(213, 94, 45) !important;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
}

.p1:hover {
  color: black;
}
.categoriesimg a {
  font-size: 18px;
  position: relative;
  color: #E0C084 !important;
  font-family: inherit;
  font-weight: 600;
  padding: 5px 20px;
}

.categoriesimg a:hover {
  color: #D55E2D !important;
}

.categoriesimg span b {
  max-width: 60%;
  font-weight: normal;
}

.categoriesimg:hover img {
  filter: blur(4px);
  opacity: 0.8;
}

.categoriesimg:hover span {
  display: flex;
}

.divider {
  z-index: 1;
  height: auto;
  padding-top: 6px !important;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: block;
  float: left;
}

.dividerBlock {
  float: left;
  display: block;
  clear: both;
  margin: 0px auto;
  width: 90%;
  border-bottom: 2px solid #D55E2D;
  position: relative;
  text-align: center;
  margin-left: 5%;
}

.car3dc>div>div>div:nth-child(1) {
  font-size: 11rem !important;
}

.carouseldesc {
  position: absolute !important;
  height: auto;
  overflow: hidden;
  position: relative;
  bottom: 0;
  background: hsla(0, 0%, 100%, .66) !important;
  width: 100%;
  padding: 5%;
}

.categoriesimg:hover .carouselmore {
  display: block;
}

.categoriesimg .carouselmore {
  display: none
}
.categoriesimg div {
  padding: 8px 16px 8px 0;
}

.categoriesimg span {
  font-size: 18px;
  position: relative;
  color: #D55E2D;
  font-family: inherit;
  padding: 15px 20px;
  font-weight: 600;
}

.carousel h4 span {
  color: #D55E2D !important;
  margin-left: 10px;
  background: #F9F2E6 !important;
  border-radius: 50px;
  font-size: 1em;
  width: 35px;
  height: 35px;
  line-height: 35px;
}




.categoriesimg:hover img {
  filter: blur(4px);
  opacity: 0.8;
}

.categoriesimg:hover .carouselmore {
  display: block;
}

.categoriesimg:hover h3 {
  height: auto;
}

.categoriesimg p {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  line-height: 20px;
  color: #323F5F;
  text-align: left;
  font-family: inherit;
  padding: 5px 20px;
  height: 46px;
  display: flex;
  align-content: center;
  align-items: center;


  display: -webkit-box;
  /* -webkit-line-clamp: 2; */
  -webkit-box-orient: vertical;
}


@media screen and (min-width: 681px) and (max-width: 1180px) {
  .gridContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 680px) {

  .top_banner {
    width: 100%;
    background-image: url("");
    background-position: bottom -13px right 0px;
    background-repeat: round;
    height: 233px;
  }

  .top_banner img {
    width: 100%;
    top: 0px;
    position: relative;
    opacity: 0.6;
    height: 222px;

  }

  .top_banner_txt h2 {
    font-size: 22px;
    margin: 20px 30px 30px 70px;
    line-height: 1.8rem;
    font-weight: bold;
    width: 73%;
  }

  .top_banner_txt {
    position: absolute;
    top: -210px;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }

  .top_banner_txt1 {
    color: #4A4A4A;
    font-size: 24px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt2 {
    color: black;
    font-size: 22px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt1 img {
    width: 28px;
    height: auto;
  }

  .top_banner_txt1 {
    color: black;
    font-size: 28px;
    width: 85%;
    margin: 0 auto;
    padding-top: 280px;
  }

  .gridContainer {
    grid-template-columns: auto;
  }
}