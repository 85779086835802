.modal_overlay {
  z-index: 9999;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding-top: 4.5rem;
}

.modal_box {
  display: block;
  background: #fafafa;
  width: 74%;
  height: 82%;
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid rgb(224, 192, 132);
  overflow-y: scroll;
}

.modal_box canvas{
  border: 2px solid rgb(224, 192, 132);
  height: 54.4vh !important;
  border-left-color: transparent;
  border-right-color: transparent;
  display: flex !important;
  align-items: end !important;
}

@media screen and (max-width: 917px) {
  .modal_box>div:nth-child(2)>div {
    max-height: 50vh !important;
  }
  .modal_box h2 {
    font-size: 1.5rem;
  }

  .modal_box {
    height: 90% !important;
  }
}
