
.top_banner{
  width:100%;
  background-color: rgba(255, 255, 255, 0.29);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  
  border-radius: 0px;;
  background-repeat:revert;
  background-size: cover;
  height: 431px;
}

.top_banner img{
  width:100%;
 
  position:relative;
}

.top_banner_txt{
  top: 0px;
  text-align: left;
  margin-bottom: 0.4em;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
/* Note: backdrop-filter has minimal browser support */
  border-radius: 0px;
}

.top_banner_txt h2{
  font-size:35px;
}

.top_banner_txt1{
  color: black;
  font-size: 28px;
  width:45%;
  margin:0 auto;
  padding-top:150px;
  line-height: 28px;
}

.top_banner_txt h2{
  color: black;
  text-align: left;
  width: 45%;
  margin: 0 auto;
  padding-top: 20px;
  font-size: 28px;
}

.top_banner_txt2{
  color: black;
  font-size: 18px;
}
.top_banner_txt1 img{
  width:28px;
  height:auto;
  position:relative;
  float:left;
  display:inline;
  margin-right:10px;
}

.top_banner_txt ul li{
  list-style-type: disc;
  font-size:30px;
  line-height:32px;
  margin: 20px 0;
}
.top_banner_img{
  position:absolute;
  top:-150px;
  z-index:-999;
  width: 100%;
  height: 531px;
  right:0px;
  left:100%;
  /* max-height: 580px; */
  overflow: hidden;
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  border-radius: 0px 0px 0px 200px;
}
.top_banner_img img{
  height: auto;
  width:auto;
  float:none;
  top:-450px;
}
.dummy{display:none}
/* .bannerImage {
  border-bottom-right-radius: 140px 140px;
} */

.loading{
  font-size:24px;
  font-weight: 600;
  height: 700px;
  margin: 20px 0;
}

.thumb{
  height:180px;
  width:auto;
  margin:0 auto;
}
@media screen and (max-width: 680px) {
  
  .top_banner{
    width:100%;
    background-image: url("");
    background-position: bottom -13px right 0px;
    background-repeat:round;
    /* height: 200px; */
  }

  .top_banner img{
    max-width:100%;
    top:0px;
    position:relative;
    opacity: 0.6;
    /* height: 222px; */

  }

  .top_banner_txt h2{
    font-size: 22px;
    margin: 20px 30px 30px 18px;
    line-height: 1.8rem;
    font-weight: bold;
  }

  .top_banner_txt{
    position: absolute;
    top: -10vh;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }  
  .top_banner_txt1{
    color: #4A4A4A;
    font-size: 24px;
    margin: 0px 10px 8px 18px;
  }
  .top_banner_txt2{
    color: black;
    font-size: 22px;
    margin: 0px 10px 8px 18px;
  }


}