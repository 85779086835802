.sidebar {
    position: absolute;
    top: 0px;
    left: -100%;
    height: 800px;
    width: 50%;
    transition: left 0.3s ease-in-out;
    background: #F9F2E6;
    box-shadow: 4px 4px 4px rgba(81, 36, 18, 0.15);
  }
  .sidebar_open h2{
    font-family: inherit;
    font-style: normal;
    font-weight: 700!important;
    font-size: 21px!important;
    line-height: 27px!important;
    color: #323F5F!important;
    padding:15px;
  }
  .inner{
    color: #D55E2D!important;
    padding:14px;
    font-size: 18px!important;
    line-height: 20px!important;
    font-weight: normal!important;
    height:100%;
    overflow-y: scroll; 
    border-left:4px solid rgb(81 36 18 / 15%)
  }
  .inner h3{
    padding-bottom: 20px;
    position: relative;
    float: left;
    display: block;
    width: 100%;
    font-weight: 700!important;
  }
  .dummy{
    display:none;
  }

  .sidebar_toggle {
    position: absolute;
    top: 0px;
    right: -52px;
    z-index: 99999;
    background:transparent;
    border:none;
    padding:0px;
    cursor: pointer;
  }

  .btn{
    float:right;
    position:relative;
  }

  .inner1{
    position:relative;
    display:block;
    float:none;
    width:100%;
    padding-bottom:30px
  }
  .inner1 img{
    margin-left:10px;
  }
.sidebar_open {
    position: absolute;
    top: 0px;
    left: 403px;
    height: 100%;
    width: 63vw;
    transition: left 0.3s ease-in-out;
    background: #F9F2E6;
    box-shadow: 4px 4px 4px rgba(81, 36, 18, 0.15);
  }

  .carouselicon img{
    margin:0px 10px;
    margin-left:0px
  }

  .checkboxcontainer input{
  box-sizing: border-box;
    padding: 0;
    display: inline;
    float: left;
    position: relative;
    margin-top: 5px;
}
.checkboxcontainer {
    border-top: 0.5px solid #D55E2D;
    padding:20px;
}

.list_item{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px;
  position: relative;
  overflow: hidden;

}

.list_item:hover .thumb{
  filter: blur(4px);
  opacity:0.8;
}

.list_item:hover .btnthumb{
  background:#323f5f;
  border:1px solid #D55E2D;
  color:white!important
}
.list_item .thumb{
    flex: 22%;
    color:#323F5F!important;
    padding:0px 0px
}
.list_item .titlethumb{
  flex: 51%;
  color:#323F5F!important;
  padding:0px 20px;
}
.list_item .btnthumb{
  flex: 24%;
  color:white!important;
  text-align:center;
  font-weight:normal;
}
.list_item strong{
  margin-bottom:20px;
  position: relative;
  display:block;
  font-size:18px;
}
.p{line-height:20px; height:40px;overflow:hidden}

.thumb{
  width:100%;
  height:150px;
  display:inline;
  padding-right:10px;
  background-position:center center;
  background-size:cover;
  border-radius: 8px;
}
.carouselicon{
  position:relative;
  margin-top:20px;
  color:#D55E2D
}

.thumb span{
  display:none;
}
.thumb img{
  width:100%;
  height:auto;
  float:none;
}
.btnthumbicon{
  padding-right:10px;
}
.btnthumb{
  background:#D55E2D;
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  height:32px;
  position:relative;
border-radius: 22px;
padding:0;
color:white!important;
}
.btnthumb a{
color:white!important;;
font-size:18px;
}