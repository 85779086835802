.container{
  border:1px solid grey;
  border-radius: 12px;
  padding: 30px;
  font-size: 16px;
  margin: 20px 80px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.h3{
 font-size: 28px;
 font-weight: 600;
}

.h3::selection{
  background-color: yellow;
  color:red;
}


@media screen and (max-width: 680px) {
  
  .container{
    padding: 14px;
    margin: 20px 10px;
  }
  
}