.top_banner {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.29);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px;
  ;
  background-repeat: revert;
  background-size: cover;
  height: 431px;
}

.top_banner img {
  width: 100%;
  position: relative;
}

.top_banner_txt {
  width: 45%;
  height: 650px;
  background: rgba(249, 242, 230, 0.7);
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 200px 0px;
}

.top_banner_txt div {
  padding: 90px;
  position: absolute;
  left: 0;
  top: 20%;
}

.top_banner_txt h2 {
  font-size: 35px;
}

.top_banner_txt1 {
  color: black;
  font-size: 28px;
  width: 60%;
  margin: 0 auto;
  padding-top: 100px;
  line-height: 45px;
}

.top_banner_txt1 a {
  color: #D55E2D !important;
  font-size: 20px !important;
  background: #F9F2E6;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt1 a:hover {
  color: #F9F2E6 !important;
  font-size: 20px !important;
  background: #D55E2D;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt h2 {
  color: black;
  text-align: left;
  width: 60%;
  /* margin: 0 auto; */
  padding-top: 20px;
  font-size: 42px;
}

.top_banner_txt2 {
  color: black;
  font-size: 18px;
}

.top_banner_txt1 img {
  width: 28px;
  height: auto;
  position: relative;
  float: left;
  display: inline;
  margin-right: 10px;
}

.top_banner_txt ul li {
  list-style-type: disc;
  font-size: 30px;
  line-height: 32px;
  margin: 20px 0;
}

.top_banner_img {
  position: absolute;
  top: -150px;
  z-index: -999;
  width: 100%;
  height: 531px;
  right: 0px;
  left: 100%;
  /* max-height: 580px; */
  overflow: hidden;
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  border-radius: 0px 0px 0px 200px;
}

.top_banner_img img {
  height: auto;
  width: auto;
  float: none;
  top: -450px;
}


.btn1 {
  border-radius: 40px;
  padding: 15px 25px;
  margin: 10px;
  border: 3px solid #323F5F;
  border-radius: 88px;
  position: relative;
  float: left;
  font-size: 22px;
  background: #323F5F;
}

.btn2 {
  border: 3px solid #323F5F;
  border-radius: 88px;
  padding: 15px 25px;
  margin: 10px;
  position: relative;
  float: left;
  font-size: 22px;
}

.btn2:hover {
  background: #323F5F;
}

.btn2:hover>img {
  filter: brightness(200%);
}

.btn1:hover>img {
  filter: brightness(40%)
}

.btn1:hover {
  background: white;
}

.top_banner_txt span {
  position: relative;
  float: left;
  margin: 10px;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #323F5F;
  padding-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

a.btn1 {
  color: white;
}

a.btn2 {
  color: #323F5F !important
}

a.btn2:hover {
  color: white !important
}

a.btn1:hover {
  color: #323F5F !important
}

.btn1 img,
.btn2 img {
  height: 20px;
  width: auto;
  margin-right: 8px;
}

.loading {
  font-size: 24px;
  font-weight: 600;
  height: 700px;
  margin: 20px 0;
}

.gallery {
  color: black !important;
  font-weight: normal !important
}

.gallery1 {
  color: #323F5F !important;
  font-weight: 900 !important
}

.top_cat {
  width: 92%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.categoriesimg {
  width: 33.3%;
  margin: 0 auto;
  float: left;
  position: relative;
  display: inline;
  padding: 10px;
  height: auto;
  overflow: hidden;
  max-height: 20vw;
}

.categoriesimg img {
  width: 100%;
  float: left;
  position: relative;
  display: block;
  padding: 5px;
  overflow: hidden;
}

.categoriesimg span {
  float: right;
  position: absolute;
  display: block;
  padding: 0px;
  top: 15px;
  right: 15px;
  text-align: right;
  font-size: 1.1rem;
  color: #323F5F;
  max-width: 50%;
  /* Note: backdrop-filter has minimal browser support */
  background: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 0px 51px;
  padding: 20px;
  /*display:none;*/
}

.categoriesimg img:hover {
  filter: blur(4px);
  opacity: 0.8;
}

.categoriesimg:hover span {
  display: block;
}

.divider {
  z-index: 1;
  height: auto;
  padding-top: 6px !important;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: block;
  float: left;
}

.dividerBlock {
  float: left;
  display: block;
  clear: both;
  margin: 0px auto;
  width: 90%;
  border-bottom: 2px solid #D55E2D;
  position: relative;
  text-align: center;
  margin-left: 5%;
}

.filters {
  width: 45%;
  position: relative;
  margin: 0 auto;
  color: #323F5F;
  margin-right: 0px;
  display: grid;
  grid-template-columns: 90%;
  margin-bottom: 40px;
}

.filters>ul>li {
  float: left;
}

.filters>ul>li:nth-child(2) {
  float: right;
  /* display: flex; */
}

.filters>ul>li:nth-child(3) {
  float: right;
  /* display: flex; */
}


.filters>ul>li:nth-child(4) {
  float: right !important;
  /* display: flex; */
}

.type>div,
.month>div,
.days>div {
  background-color: #F9F2E6 !important;
  color: #D55E2D;
  border: 2px solid #E0C084;
  border-radius: 40px;
  font-weight: 600;
}

.filters h4 {
  display: inline-block;
}

.type>div:hover,
.month>div:hover,
.days>div:hover {
  border-color: #D55E2D !important;
}

.tabsNav {
  color: #000;

}

.tabsNav div:before {
  border: none !important;

}

.tabsNav>div div {
  font-size: 1.2rem !important;
  height: 53px;
  align-items: center;
  margin: 10px;
  border-radius: 88px;
}

.tabsNav>div>div>div>div {
  /* background-color: red; */
  line-height: 3.4rem !important;
  padding: 0rem;
}

.tabsNav>div>div>div>div {
  margin: 0 !important;
  font-weight: bold
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
  margin: 10px;
  border-radius: 88px;
}

.tabsNav>div>div>div>div:hover {
  font-weight: bold;
  color: #fff !important;
  /* font-weight: normal !important; */
  background-color: #323f5f !important;
  margin: 10px;
  border-radius: 88px;
}

.tabsNav>div>div>div>div:nth-child(1):hover {
  font-weight: bold;
  color: #fff !important;
  /* font-weight: normal !important; */
  background-color: #E0C084 !important;
  margin: 10px;
  border-radius: 88px;
}

.tabsNav>div>div>div>div:nth-child(2):hover {
  font-weight: bold;
  color: #fff !important;
  /* font-weight: normal !important; */
  background-color: #d55e2d !important;
  margin: 10px;
  border-radius: 88px;

}

.tabsNav>div>div>div>div {
  /* background-color: red; */
  line-height: 3.4rem !important;
  padding: 1rem;
  color: #323f5f;
  margin: .4em !important;
}

.tabsNav>div:nth-child(2) {
  display: none;
}

.tabsNav1Active>div>div>div>div:nth-child(1) {
  background-color: #E0C084 !important;
}

.tabsNav2Active>div>div>div>div:nth-child(2) {
  background-color: #d55e2d !important;
  font-weight: bold;
  color: white;
}

.tabsNav3Active>div>div>div>div:nth-child(3) {
  background-color: #323f5f !important;
}


@media screen and (min-width: 681px) and (max-width: 1180px) {

  .top_banner_txt div {
    padding: 50px 5vw 90px 40px;
  }

  .top_banner_txt h2 {
    width: 100%;
  }

  .btn1 {
    font-size: 14px !important;
  }

  .categoriesimg {
    width: 50%;
    max-height: 34vw;
  }

  .top_cat {
    width: 100%;
  }
}

@media screen and (max-width: 680px) {
  .tabsNav>div div {
    justify-content: center !important;
    flex-direction: column;
    margin: 0 auto;
  }

  .tabsNav>div>div {
    height: 200px !important;
    align-items: start !important;
    justify-content: center !important;
  }

  .top_cat {
    width: 100%;
  }

  .top_banner_txt {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
    backdrop-filter: blur(25px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 0px 0px 200px 0px;
  }

  .categoriesimg {
    height: auto;
  }

  .top_banner {
    width: 100%;
    background-image: url("");
    background-position: bottom -13px right 0px;
    background-repeat: round;
    height: 200px;
  }

  .top_banner img {
    width: 100%;
    top: 0px;
    position: relative;
    opacity: 0.6;
    height: 222px;

  }

  .top_banner_txt h2 {
    font-size: 22px;
    margin: 20px 30px 30px 18px;
    line-height: 1.8rem;
    font-weight: bold;
    width: 100%;
  }

  .top_banner_txt {
    position: relative;
    top: -200px;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }

  .top_banner_txt div {
    padding: 90px 0;
    position: absolute;
    left: 0;
    top: 20%;
  }

  .top_banner_txt1 {
    color: #4A4A4A;
    font-size: 24px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt2 {
    color: black;
    font-size: 22px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt span {
    margin: 135px 35px 15px 45px;
  }

  .categoriesimg {
    width: 100%;
    max-height: none;
  }

  .categoriesimg span {
    font-size: 1rem;
  }



}