.top_banner_txt {
  height: 113%;
  background: rgba(249, 242, 230, 0.7);
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 200px 0px;
}

.top_banner_txt div {
  padding: 90px;
  position: absolute;
  left: 0;
  top: 20%;
  z-index: 2;
}

.top_banner_txt {
  position: absolute;
  width: 50vw;
  /* max-height: 75vh; */
  background: rgba(249, 242, 230, 0.7);
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  backdrop-filter: blur(25px);
  border-radius: 0px 0px 200px 0px;
  overflow: hidden;
  left: 0;
  z-index: 2;
}

.top_banner_txt div {
  padding: 90px;
  position: absolute;
  left: 0;
  top: 20%;
}

.top_banner_txt h2 {
  font-size: 35px;
}

.top_banner_txt1 {
  color: black;
  font-size: 28px;
  width: 60%;
  margin: 0 auto;
  padding-top: 100px;
  line-height: 45px;
}

.top_banner_txt1 a {
  color: #D55E2D !important;
  font-size: 20px !important;
  background: #F9F2E6;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt1 a:hover {
  color: #F9F2E6 !important;
  font-size: 20px !important;
  background: #D55E2D;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt h2 {
  color: black;
  text-align: left;
  width: 60%;
  /* margin: 0 auto; */
  padding-top: 20px;
  font-size: 42px;
}

.top_banner_txt2 {
  color: black;
  font-size: 18px;
}

.top_banner_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  /* Specify the desired height for the image */
  overflow: hidden;
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  border-radius: 0px 0px 0px 200px;
}

.top_banner_img img {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: top;
}

.top_banner_txt ul li {
  list-style-type: disc;
  font-size: 30px;
  line-height: 32px;
  margin: 20px 0;
}

.top_banner_txt span {
  position: relative;
  float: left;
  margin: 10px;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #323F5F;
  padding-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.top_banner_txt .content {
  padding: 90px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 757px;
}

.btn1 {
  border-radius: 40px;
  padding: 15px 25px;
  margin: 10px;
  border: 3px solid #323F5F;
  border-radius: 88px;
  position: relative;
  float: left;
  font-size: 22px;
  background: #323F5F;
  color: white !important;
}

.btn2 {
  border: 3px solid #323F5F;
  border-radius: 88px;
  padding: 15px 25px;
  margin: 10px;
  position: relative;
  float: left;
  font-size: 22px;
}

.btn2:hover {
  background: #323F5F;
}

.btn2:hover>img {
  filter: brightness(200%);
}

.btn1:hover>img {
  filter: brightness(40%)
}

.btn1:hover {
  background: white;
}

.top_banner_txt span {
  position: relative;
  float: left;
  margin: 10px;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  text-align: justify;
  color: #323F5F;
  padding-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

a.btn1 {
  color: white !important
}

a.btn2 {
  color: #323F5F !important
}

a.btn2:hover {
  color: white !important
}

a.btn1:hover {
  color: #323F5F !important
}

.btn1 img,
.btn2 img {
  height: 20px;
  width: auto;
  margin-right: 8px;
}

.divider {
  z-index: 1;
  height: 40px;
  padding-top: 10px;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  display: block;
  float: left;
}

.divider2 {
  z-index: 1;
  height: 110px;
  padding-top: 90px;
  width: 100%;
  background-color: #ffffff;
  position: relative;
  /* margin-top: 150px; */
}

.dividerBlock {
  margin: 0px auto;
  width: 80%;
  border-bottom: 1.5px solid #D55E2D;
}

.carouselev {
  background-color: white;
  padding: 0px 0px 150px 90px;
  position: relative;
  width: 100%;
}

.carouselev h2 {
  color: black !important;
  font-size: 1.8rem;
  font-weight: 600;
  padding-left: 10px;
  text-align: left;
  padding-bottom: 14px;
  font-family: 'Source Serif 4';
}

.btnev a {
  float: right;
  margin-right: 50px;
  margin-right: 10px;
  color: #E0C084 !important;
}

.btnev a:after {
  content: '→';
  margin-left: 10px;
  background: #F9F2E6 !important;
  padding: 0px 15px 4px 15px;
  border-radius: 20px;
  font-size: 1.4em;
}

.bgimg1 {
  width: 52%;
}

.videoWrapper {
  max-height: 600px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
}

.youtubePlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtubePlayer>iframe {
  width: 100% !important;
  height: 100%;
}

@media screen and (max-width: 680px) {

  .divider {
    height: 54px;
    padding-top: 28px;
  }

  .divider2 {
    /* height: 50px;
    padding-top: 20px; */
    height: 0px;
    padding-top: 90px;

  }

}

@media screen and (min-width: 1650px) {
  .top_banner_txt {
    width: 48%;
  }

  .bgimg1 {
    width: 55% !important;
  }

}

@media screen and (max-width: 1280px) {
  .top_banner_txt {
    width: 58%;
    /* height:450px; */
  }

  .bgimg1 {
    width: 58%;
  }

  .top_banner_txt div {
    padding: 45px 30px;
  }

  .top_banner_txt span {
    font-size: 18px;
  }

  .btn1 {
    border-radius: 40px;
    padding: 10px 15px;
    margin: 10px;
    border: 3px solid #323F5F;
    border-radius: 88px;
    position: relative;
    float: left;
    font-size: 18px;
    background: #323F5F;
    color: white !important;
    display: flex;
    align-items: center;
  }

  .btn2 {
    border: 3px solid #323F5F;
    border-radius: 88px;
    padding: 10px 15px;
    margin: 38px 0;
    position: relative;
    float: left;
    font-size: 18px;
    display: flex;
    align-items: center;
  }

}



@media screen and (max-width: 1080px) {
  .top_banner_txt div {
    /* padding: 40px 90px; */
    position: absolute;
    left: 0;
    top: 20%;
  }


}


@media screen and (max-width: 680px) {
  .bgimg {
    margin-top: 0 !important;
  }

  .bgimg1 {
    /* margin-top: -130px !important; */
    margin-top: 1rem;
    width: 100%;
  }

  .top_banner_txt .content {
    transform: translateY(0%);
  }

  .top_banner_txt {
    width: 100%;
    height: 450px;
  }

  .top_banner_txt div {
    padding: 40px 20px;
    width: 88%;
  }

  .top_banner_txt span {
    font-size: 16px;
  }

  .btn1 {
    border-radius: 40px;
    padding: 10px 15px;
    margin: 10px;
    border: 3px solid #323F5F;
    border-radius: 88px;
    position: relative;
    float: left;
    font-size: 18px;
    background: #323F5F;
    color: white !important;
  }

  .btn2 {
    border: 3px solid #323F5F;
    border-radius: 88px;
    padding: 10px 15px;
    margin: 10px;
    position: relative;
    float: left;
    font-size: 18px;
  }




  .top_banner_txt {
    width: 95%;

  }

  .top_banner_txt div {
    padding: 50px 5vw 90px 40px;
  }

  .top_banner_txt {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
    backdrop-filter: blur(25px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 0px 0px 200px 0px;
  }

  .top_banner {
    width: 100%;
    background-image: url("");
    background-position: bottom -13px right 0px;
    background-repeat: round;
    height: 200px;
  }

  .top_banner img {
    width: 100%;
    top: 0px;
    position: relative;
    opacity: 0.6;
    height: 222px;

  }

  .top_banner_txt h2 {
    font-size: 22px;
    margin: 20px 30px 30px 18px;
    line-height: 1.8rem;
    font-weight: bold;
    width: 100%;
  }

  .top_banner_txt {
    position: relative;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }

  .top_banner_txt div {
    /* padding: 90px 0; */
    position: absolute;
    left: 0;
    top: 6% !important;
    padding: 0 17px !important
  }

  .top_banner_txt1 {
    color: #4A4A4A;
    font-size: 24px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt2 {
    color: black;
    font-size: 22px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt span {
    margin: 0 15px;
  }

}