.title {
  font-size: 28px;
  padding: 20px 0px 14px 10px;
  font-weight: bold;
  text-align: left;
  color:black!important;
  font-family: inherit;
}

.p {
  text-align: justify;
  font-size: 18px;
  padding: 0px 0px 0px 10px;
}

.contentStyle{
  height: 700px;
  text-align: center;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.contentImageDescription{
  font-size: 17px;
  line-height: 1.4;
  color:rgb(59, 59, 59);
  padding:10px;
  border-radius: 12px;
  margin: auto;
  margin-top:2px;
  width: 78%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.pimg{
  width:25%;
  height:320px;
  overflow:hidden;
  display: block;
  position: relative;
  float:left;
}
.pimg img{
  height: 320px;
  width: auto;
  min-width: 100%;
  float: left;
  display: block;
  position: relative;
  padding: 10px;
}

.biblio > ul > li { font-size: 18px; padding:2px; }

.top_cat_gal {
  width: 100%;
  margin: 0 auto;
  float: left;
  position: relative;
  overflow: hidden;
}

.categoriesimggal img {
  width: 100%;
  height: 450px;
  overflow: hidden;
}

.categoriesimggal img {
  width: 100%;
  height: 450px;
  overflow: hidden;
}
.categoriesimggal {
  width: 100%;
  margin: 0 auto;
  float: left;
  position: relative;
  display: inline;
  padding: 10px;
  height: 350px;
  overflow: hidden;
}

.p1 {
  color: rgb(213, 94, 45) !important;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
}

.p1:hover {
  color: black;
}

@media only screen and (max-width: 600px) { 
  
  .contentStyle{
    height: 450px;
    color: #fff;
    line-height: 340px;
    text-align: center;
    font-size: 21px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contentImageDescription{
    font-size: 16px;
    width: 100%;
  }
  .title {
    padding: 20px 0px 0px 10px;
  }
  .p {
    text-align: justify;
    font-size: 17px;
    padding: 14px;
  }
  /* .p2 {
    text-align: justify;
    font-size: 17px;
    padding: 2px 0px 0px 0px;
  } */

}

.sketchfab_embed iframe{
  width:100%;
  height:450px;
}