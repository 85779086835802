.cookies_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 33, 36, 0.75);
    z-index: 4;
}

.cookies_container {
    position: fixed;

    display: flex;

    flex-direction: column;
    justify-content: space-around;
    bottom: 20px;
    left: 20px;
    width: 310px;
    height: 190px;
    padding: 25px;

    font-size: 16px;

    overflow: hidden;

    color: #D6D6D6;

    border-radius: 12px;
    background-color: black;

    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;

    animation: hideModal 1s forwards;

    z-index: 5;
}

.expand_cookies {
    animation: expandModal 2s forwards;
}

.accept_cookies {
    bottom: 10px;
    width: 100%;
    font-size: 21px !important;
    font-weight: 700 !important;
    line-height: 44px;

    border-radius: 12px;
    background-color: var(--mainBackgroundColor, #CAB482) !important;
}

@media only screen and (max-width: 408px) {
    .cookies_container {
        width: calc(100% - 90px);
    }
}

@keyframes hideModal {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(calc(100% + 20px));
    }
}

@keyframes expandModal {
    0% {
        transform: translateY(calc(100% + 20px));
    }
    100% {
        transform: translateY(0);
    }
}
