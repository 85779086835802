.title{
  padding:4px 0px 4px 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: black;
}
.address{
  padding:4px 0px 4px 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: black;
}
.gpsInfoWindow img{
  margin-top:-10px;
  height:auto;
  width: 260px;
}

.loading{
  font-size:24px;
  font-weight: 600;
  height: 700px;
  margin: 20px 0;
}

@media only screen and (max-width: 600px) { 

}