
.top_banner{
  width:100%;
  background-color: rgba(255, 255, 255, 0.29);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  
  border-radius: 0px;;
  background-repeat:revert;
  background-size: cover;
  height: 431px;
}

.top_banner img{
  width:100%;
  position:relative;
}

.top_banner_txt{
  top: 0px;
  text-align: left;
  margin-bottom: 0.4em;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
/* Note: backdrop-filter has minimal browser support */
  border-radius: 0px;
}

.top_banner_txt h2{
  font-size:35px;
}

.top_banner_txt1{
  color: black;
  font-size: 28px;
  width:45%;
  margin:0 auto;
  padding-top:150px;
  line-height: 28px;
}

.top_banner_txt h2{
  color: black;
  text-align: left;
  width: 45%;
  margin: 0 auto;
  padding-top: 0px;
  font-size: 42px;
}
.top_banner_txt h2 a{
color: #D55E2D!important;
font-size: 20px!important;
background: #F9F2E6;
border: 2px solid #E0C084;
border-radius: 40px;
padding: 10px 20px;
}

.top_banner_txt2{
  color: black;
  font-size: 18px;
}
.top_banner_txt1 img{
  width:28px;
  height:auto;
  position:relative;
  float:left;
  display:inline;
  margin-right:10px;
  margin-top:20px;
}

.top_banner_txt ul li{
  list-style-type: disc;
  font-size:30px;
  line-height:32px;
  margin: 20px 0;
}
.top_banner_img{
  position:absolute;
  top:-150px;
  z-index:-999;
  width: 100%;
  height: 531px;
  right:0px;
  left:100%;
  /* max-height: 580px; */
  overflow: hidden;
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  border-radius: 0px 0px 0px 200px;
}
.top_banner_img img{
  height: auto;
  width:auto;
  float:none;
  top:-450px;
}

.p {
  text-align: justify;
  font-size: 18px;
  padding: 0px 0px 0px 10px;
}

.gallerycat{
  width:100%;
  float:left;
  height:auto;
  display:block;
}
/* .bannerImage {
  border-bottom-right-radius: 140px 140px;
} */

.loading{
  font-size:24px;
  font-weight: 600;
  height: 700px;
  margin: 20px 0;
}

.gallery{color:black!important; font-weight:normal!important}
.gallery1{color:#323F5F!important;font-weight:900!important}
.top_cat{
  width:80%;
  margin:0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}

.categoriesimg{
  width:33.3%;
  margin:0 auto;
  float:left;
  position:relative;
  display:inline;
  padding:10px;
  height:350px;
  overflow:hidden;
}
.categoriesimggal{
  width:100%;
  margin:0 auto;
  float:left;
  position:relative;
  display:inline;
  padding:10px;
  height:350px;
  overflow:hidden;
}
.top_cat_gal{
  width:80%;
  margin:0 auto;
  float:left;
  position:relative;
  overflow:hidden;
}
.categoriesimggal img{
  width:100%;
  height:450px;
  overflow:hidden;
}
.categoriesimggal img{
  width:100%;
  height:450px;
  overflow:hidden;
}
.categoriesimg img{
  width:100%;
  float:left;
  position:relative;
  display:block;
  padding:5px;
  overflow:hidden;
}
.categoriesimg span{
  float: right;
  position: absolute;
  display: block;
  padding: 0px;
  top: 15px;
  right: 15px;
  text-align: right;
  font-size: 20px;
  color: #323F5F;
  max-width: 50%;
  background: rgba(255, 255, 255, 0.55);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 0px 0px 0px 51px;
  padding: 20px;
  font-weight:bold!important
}
.categoriesimg span b{
  font-weight:bold!important
}
.p{
  height: 75px!important;
  overflow: hidden;
  font-size: 15px;
  line-height: 18px;
  padding: 20px 0px;
  margin-bottom: 20px;
  font-weight: normal;
}
.p1{
  color:rgb(213, 94, 45) !important;
  cursor: pointer;
  font-size: 15px;
  line-height: 18px;
}
.p1:hover{
color:black;
}
.categoriesimg span b{
  max-width:60%;
  font-weight:normal;
}
.categoriesimg:hover img{
filter: blur(4px);
  opacity: 0.8;
}

.divider {
  z-index: 1;
  height: auto;
  padding-top: 6px !important;
  width: 100%;
  background-color: transparent;
  position: relative;
  display: block;
  float: left;
}

.dividerBlock {
  float: left;
  display: block;
  clear: both;
  margin: 0px auto;
  width: 90%;
  border-bottom: 2px solid #D55E2D;
  position: relative;
  text-align: center;
  margin-left: 5%;
}


@media screen and (max-width: 680px) {
  
  .top_banner{
    width:100%;
    background-image: url("");
    background-position: bottom -13px right 0px;
    background-repeat:round;
    height: 200px;
  }

  .top_banner img{
    width:100%;
    top:0px;
    position:relative;
    opacity: 0.6;
    height: 222px;

  }

  .top_banner_txt h2{
    font-size: 22px;
    margin: 20px 30px 30px 18px;
    line-height: 1.8rem;
    font-weight: bold;
  }

  .top_banner_txt{
    position: absolute;
    top: -210px;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }  
  .top_banner_txt1{
    color: #4A4A4A;
    font-size: 24px;
    margin: 0px 10px 8px 18px;
  }
  .top_banner_txt2{
    color: black;
    font-size: 22px;
    margin: 0px 10px 8px 18px;
  }


}