.container{
  height: 592px;
  background: url('../../../public/img/acropoli_background_img.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.textWrapper{
  position: absolute;
  top: 30%;
  left: 50%;
  width: 400px;
  height: 100px;
  margin-left: -200px;
  margin-top: -29px;
}





h1 {
  margin: 0;
  text-align: center;
  color: #8f2121;
  font-family: arial;
  font-size: 56px;
  font-weight: 6 00;
}

h2 {
  margin: 0;
  text-align: center;
  color: #8d9134;
  font-family: arial;
  font-size: 46px;
  font-weight: 500;
}
h3 {
  margin: 0;
  text-align: center;
  color: #8d9134;
  font-family: arial;
  font-size: 30px;
  line-height: 2.2rem;
}


.link{
  text-align: center;
  padding-top:30px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
}



@media screen and (max-width: 680px) {

  .container{ height: 420px;}
  h1 { font-size: 50px;}
  h2 { font-size: 42px;}
  h3 { font-size: 32px; padding:2px 14px; line-height: 2.2rem;}
  .textWrapper{top: 20%;}
}