.sidebar {
  position: absolute;
  top: 0px;
  left: -403px;
  height: 800px;
  width: 403px;
  transition: left 0.3s ease-in-out;
  background: #F9F2E6;
  box-shadow: 4px 4px 4px rgba(81, 36, 18, 0.15);
}

.sidebar_open {
  background: #F9F2E6;
  box-shadow: 6px 6px 6px rgba(81, 36, 18, 0.15);
}

.sidebar_open h2 {
  /* font-family: 'Source Serif 4' !important; */
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #323F5F !important;
  padding: 15px;
}

.inner {
  color: #323F5F !important;
  padding: 14px;
  font-size: 15px !important;
  line-height: 18px !important;
  font-weight: normal !important;
  height: 100%;
}

.inner h3 {
  padding-bottom: 20px;
  position: relative;
  float: left;
  display: block;
  width: 100%;
  font-weight: 700 !important;
}

.dummy {
  display: none;
}

.sidebar_toggle {
  position: absolute;
  top: 0px;
  right: -52px;
  z-index: 99999;
  background: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
}

.sidebar_toggle1 {
  position: absolute;
  top: 0px;
  left: 403px;
  z-index: 99999;
  background: transparent;
  border: none;
  padding: 0px;
  cursor: pointer;
}

.btn {
  float: left;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.inner1 {
  position: relative;
  display: block;
  float: none;
  width: 100%;
  padding-bottom: 30px
}

.sidebar_open {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 403px;
  transition: left 0.3s ease-in-out;
  background: #dfd7d3;
  box-shadow: 4px 4px 4px rgba(81, 36, 18, 0.15);
  opacity: 0.97;
}

.carouselicon img {
  margin: 0px 10px;
}

.checkboxcontainer input {
  box-sizing: border-box;
  padding: 0;
  display: inline;
  float: left;
  position: relative;
  margin-top: 5px;
}

.checkboxcontainer {
  border-top: 0.5px solid #FFF;
  padding: 15px 20px;
}

.closeCircle {
  font-size: 36px;
  float: right;
  color: white !important;
  margin-top: -3px;
  cursor: pointer;
}

.closeCircle path {
  fill: #323F5F !important;
}

.menuContainer {
  background-color: #fff;
  margin-top: 10px;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #323F5F; */
  border-top-right-radius: 19px;
  border-bottom-right-radius: 19px;
  padding-right: 5px;
}