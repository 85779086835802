.loading {
  font-size: 24px;
  font-weight: 600;
  height: 700px;
  margin: 20px 0;
}

.timelineH2 {
  padding-top: 14px;
  color: rgb(5, 59, 5);
  font-size: 18px;
  font-weight: bold;
}

.sketchfab_embed {
  position: relative;
  display: inline-block;
  float: none;
  margin-bottom: 20px;
  margin: 10px;
  width: 23%;
  height: 320px;
  background: #D9D9D9;
  text-align: center;
}

/* .sketchfab_embed:nth-child(6),
.sketchfab_embed:nth-child(7),
.sketchfab_embed:nth-child(8),
.sketchfab_embed:nth-child(13).sketchfab_embed:nth-child(14).sketchfab_embed:nth-child(15) {
  width: 31.0%;
} */


/* .sketchfab_embed:hover{
  filter: blur(4px);
  -webkit-filter: blur(8px);
  opacity: 0.8;
} */

.sketchfab_embed img {
  height: 250px;
  width: auto;
  position: relative;
  top: 20px
}

.main {
  width: 95%;
  margin: 0 auto;

}

.main strong {
  position: relative;
  display: inline-block;
  float: none;
  margin: 10px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 21px;
  z-index: 0;
}

.figcaption {
  position: absolute;
  top: 17%;
  bottom: 25%;
  color: #FFF;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
  display: none;
  z-index: 0;
}

/* .sketchfab_embed:hover .figcaption {
  display: block;
} */

.sketchfab_embed:hover img {
  filter: blur(4px);
  opacity: 0.8;
}

.top_banner_txt {
  width: 45%;
  height: 650px;
  background: rgba(249, 242, 230, 0.7);
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 200px 0px;
}

.top_banner_txt div {
  padding: 90px;
  position: absolute;
  left: 0;
  top: 20%;
}

.top_banner_txt h2 {
  font-size: 35px;
}

.top_banner_txt1 {
  color: black;
  font-size: 28px;
  width: 60%;
  margin: 0 auto;
  padding-top: 100px;
  line-height: 45px;
}

.top_banner_txt1 a {
  color: #D55E2D !important;
  font-size: 20px !important;
  background: #F9F2E6;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt1 a:hover {
  color: #F9F2E6 !important;
  font-size: 20px !important;
  background: #D55E2D;
  border: 2px solid #E0C084;
  border-radius: 40px;
  padding: 10px 20px;
}

.top_banner_txt h2 {
  color: black;
  text-align: left;
  width: 60%;
  /* margin: 0 auto; */
  padding-top: 20px;
  font-size: 42px;
}

.top_banner_txt2 {
  color: black;
  font-size: 18px;
}

.top_banner_img {
  position: absolute;
  top: -150px;
  z-index: -999;
  width: 100%;
  height: 531px;
  right: 0px;
  left: 100%;
  /* max-height: 580px; */
  overflow: hidden;
  box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
  border-radius: 0px 0px 0px 200px;
}

.top_banner_img img {
  height: auto;
  width: auto;
  float: none;
  top: -450px;
}

.top_banner_txt ul li {
  list-style-type: disc;
  font-size: 30px;
  line-height: 32px;
  margin: 20px 0;
}

.top_banner_txt span {
  position: relative;
  float: left;
  margin: 10px;
  position: relative;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #323F5F;
  padding-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-state:checked~.read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state~.read-more-trigger:before {
  content: 'Show more';
}

.read-more-state:checked~.read-more-trigger:before {
  content: 'Show less';
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #666;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: .25em;
}

.btn {
  margin: 8px;
}

.pointTitle {
  font-size: 18px;
  padding: 28px 0px 8px 0px;
  font-weight: 500;
}

.hover {
  padding: 2px;
}

.p {
  font-size: 16px;
  text-align: justify;
}

.more,
.more:hover {
  font-size: 18px;
  color: #622323;
  text-decoration: underline !important;
}

.carousel {
  padding: 44px 0px 110px 10vw;
  background-color: #fff;
  width: 100%;
}

.carousel h2 {
  color: #323f5f;
  font-family: inherit;
  font-size: 1.8em;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 12px;
  text-align: left;
}

.carouselslider {
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.86) 0%, #FFFFFF 53.65%); */
  margin: 20px;
  margin-left: 0px;
  width: 100% !important;
  position: relative;
}

.carouselslider a {
  color: #0f0f0f;
  text-decoration: none !important;

}

.carouselimg {
  width: 100%;
  height: 290px;
  position: relative;
  overflow: hidden;

}

.carouselimg img {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  object-fit: cover;
}

/* 
.carouselslider:hover {
  filter: blur(4px);
  opacity: 0.8;
} */

.carouselslider:hover .carouselmore {
  display: block;
  filter: blur(0px) !important;
  opacity: 1;
}

.carouselslider:hover h3 {
  height: auto;
}

.carouselslider h3,
.carouselslider p img {
  padding: 10px;
  padding-bottom: 0px;
}

.carouselslider p {
  color: #323F5F !important;
  position: relative;
}

.carouselslider p img {
  float: left;
  display: inline;
}

.carouselslider span {
  font-size: 18px;
  position: relative;
  color: #D55E2D;
  font-family: inherit;
  padding: 15px 20px;
  font-weight: 600;
}

.carouselslider div {
  padding: 8px 16px 8px 0;
}

.carouselslider .carouselmore {
  display: none
}

.carouselslider a {
  font-size: 18px;
  position: relative;
  color: #E0C084 !important;
  font-family: inherit;
  font-weight: 600;
  padding-left: 10px;
}

.carouselslider a:hover {
  color: #D55E2D !important;
}

.carouselslider h3 {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  line-height: 20px;
  color: #323F5F;
  text-align: left;
  font-family: inherit;
  padding: 5px 20px;
  height: 62px;
  display: flex;
  align-content: center;
  align-items: center;
}

.carouselslider p {
  font-size: 15px;
  height: 25px;
  overflow: hidden;
}

.carousel h4 {
  display: inline;
  float: right;
  position: absolute;
  right: 10vw;
  font-size: 18px;
}

.carousel h4 a:hover {
  color: #323F5F !important;
}

.carousel h4 a {
  text-decoration: none;
  color: #D55E2D !important;
}

.carousel h4 span {
  color: #D55E2D !important;
  margin-left: 10px;
  background: #F9F2E6 !important;
  border-radius: 50px;
  font-size: 1em;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.carouseldesc {
  position: absolute !important;
  height: auto;
  overflow: hidden;
  position: relative;
  bottom: 0;
  background: hsla(0, 0%, 100%, .86) !important;
  width: 100%;
}


@media screen and (max-width: 1280px) {
  .top_banner_txt {
    width: 80%;

  }

  .sketchfab_embed {
    position: relative;
    display: inline-block;
    float: none;
    margin-bottom: 20px;
    margin: 10px;
    width: 46.5%;
    height: 280px;
    background: #D9D9D9
  }

}

@media only screen and (max-width: 1080px) {
  .mobapp_inner {
    grid-template-columns: 89%;
  }

  .part2 a {
    float: left;
    margin: 4rem auto 0 auto;
    width: 100%;
  }

  .top_banner_txt div {
    /* padding: 40px 90px; */
    position: absolute;
    left: 0;
    top: 20%;
  }
}


@media screen and (max-width: 901px) {

  .sketchfab_embed:nth-child(6),
  .sketchfab_embed:nth-child(7),
  .sketchfab_embed:nth-child(8),
  .sketchfab_embed:nth-child(13),
  .sketchfab_embed:nth-child(14),
  .sketchfab_embed:nth-child(15) {
    width: 46.5%;
  }
}

@media screen and (min-width: 681px) and (max-width: 1180px) {



  .top_banner_txt h2 {
    width: 100%;
  }
}

@media screen and (max-width: 680px) {
  .top_banner_txt div {
    padding: 50px 5vw 90px 40px;
  }

  .top_banner_txt {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 1px 15px rgba(150, 129, 91, 0.2);
    backdrop-filter: blur(25px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 0px 0px 200px 0px;
  }

  .top_banner {
    width: 100%;
    background-image: url("");
    background-position: bottom -13px right 0px;
    background-repeat: round;
    height: 200px;
  }

  .top_banner img {
    width: 100%;
    top: 0px;
    position: relative;
    opacity: 0.6;
    height: 222px;

  }

  .top_banner_txt h2 {
    font-size: 22px;
    margin: 20px 30px 30px 18px;
    line-height: 1.8rem;
    font-weight: bold;
    width: 100%;
  }

  .top_banner_txt {
    position: relative;
    top: -200px;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }

  .top_banner_txt div {
    padding: 90px 0;
    position: absolute;
    left: 0;
    top: 20%;
  }

  .top_banner_txt1 {
    color: #4A4A4A;
    font-size: 24px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt2 {
    color: black;
    font-size: 22px;
    margin: 0px 10px 8px 18px;
  }

  .top_banner_txt span {
    margin: 135px 35px 15px 45px;
  }
}

.carousel {
  padding: 0;
}

.spin>div>div {
  padding-top: 80px;
  margin: 0 !important;
  height: 67vh;
}
@media screen and (max-width: 680px) {
  .top_banner_txt {
    width: 95%;
  }

  .sketchfab_embed {
    position: relative;
    display: inline-block;
    float: none;
    margin-bottom: 20px;
    margin: 10px;
    width: 95% !important;
    height: 280px;
    background: #D9D9D9
  }

  .figcaption {
    position: absolute;
    top: 17%;
    bottom: 25%;
    color: #FFF;
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
    display: block;
    z-index: 0;
  }
}