.logotxt {
    color: #323F5F;
    font-size: 22px;
    margin-top: 10px;
}

.logotxt strong {
    color: #BBA15A;
    font-size: 22px;
    letter-spacing: 8px;
    position: relative;
    top: 15px;
}

.logotxt b {
    position: relative;
    top: 15px;
}

.logoimg span {
    max-width: 150px;
    border-left: 2px solid #323F5F;
    font-family: 'Source Serif 4';
    font-style: normal;
    font-weight: 600;
    margin-left: 11px;
    padding-left: 18px;

}

.divider {
    z-index: 1;
    height: auto;
    padding-top: 4px !important;
    width: 100%;
    background-color: transparent;
    position: relative;
    display: block;
    float: left;
}

.dividerBlock {
    float: left;
    display: block;
    clear: both;
    margin: 0px auto;
    width: 90%;
    border-bottom: 2px solid #D55E2D;
    position: relative;
    text-align: center;
    margin-left: 5%;
}

.socialSidebar {
    display: flex;
    bottom: 0;
    position: relative;
    top: 10px;
}

.socialSidebar a{
    color: #323f5f;
    font-size: 1.6rem;
    margin-left: 15px !important;
}

.socialSidebar img {
    max-width: 40px;
    
}

.menu {
    z-index: 999999999 !important;
}