.sidebar {
    position: absolute;
    top: 0px;
    left: -403px;
    height: 800px;
    width: 403px;
    transition: left 0.3s ease-in-out;
    background: #F9F2E6;
    box-shadow: 4px 4px 4px rgba(81, 36, 18, 0.15);
  }

  .sidebar_open{
    background: #F9F2E6;
    box-shadow: 6px 6px 6px rgba(81, 36, 18, 0.15);
  }

  .sidebar_open h2{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    font-style: normal;
    font-weight: 700!important;
    font-size: 20px!important;
    line-height: 27px!important;
    color: #323F5F!important;
    padding:15px;
  }
  .inner{
    color: #D55E2D!important;
    padding:14px;
    font-size: 15px!important;
    line-height: 18px!important;
    font-weight: normal!important;
    height:100%;
  }
  .inner h3{
    padding-bottom: 20px;
    position: relative;
    float: left;
    display: block;
    width: 100%;
    font-weight: 700!important;
  }
  .dummy{
    display:none;
  }

  .sidebar_toggle {
    position: absolute;
    top: 0px;
    right: -52px;
    z-index: 99999;
    background:transparent;
    border:none;
    padding:0px;
    cursor: pointer;
  }
  .sidebar_toggle1 {
    position: absolute;
    top: 0px;
    left: 89.2vw;
    z-index: 99999;
    background:transparent;
    border:none;
    padding:0px;
    cursor: pointer;
  }
  .btn{
    float:right;
    position:relative;
    margin-left:10px;
  }

  .inner1{
    position:relative;
    display:block;
    float:none;
    width:100%;
    padding-bottom:30px
  }

.sidebar_open {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 403px;
    transition: left 0.3s ease-in-out;
    background: #dfd7d3;
    box-shadow: 4px 4px 4px rgba(81, 36, 18, 0.15);
    opacity: 0.97;
  }

  .carouselicon img{
    margin:0px 10px;
  }
hr {
  color: white !important;
}
  .categoriesrect:first-child {
    border-top: none !important
  }
.categoriesrect{
  padding:15px;
  margin: 0 15px;
  border-top: 0.5px solid #FFF;
  /* box-shadow: -2px 2px 4px rgba(213, 94, 45, 0.5), 2px -2px 4px rgba(213, 94, 45, 0.5); */
  /* border-radius: 6px; */
}

.categoriesrect svg{
   display:none!important;
}
.categoriesrect .ant-collapse-header::before{
  width:30px;
  height:40px;
  background:red;
  content:'1'
}

  .checkboxcontainer input{
    box-sizing: border-box;
    padding: 0;
    display: inline;
    float: left;
    position: relative;
    margin-top: 5px;
}
.checkboxcontainer {
    border-top: 0.5px solid #D55E2D;
    padding:15px 20px;
}

.categoriesinner ol {
  counter-reset: list;
  margin: 0; 
  padding-inline-start: 0em;
}
  
.categoriesinner ol > li {
  list-style: none;
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 35px;
  max-width:85%;
}
  
  .categoriesinner ol > li:before {
    counter-increment: list;
    content: counter(list);
    position: absolute;
    background: #D55E2D;
    width: 35px;
    height: 35px;
    padding: 0px;
    border-radius: 35px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    color: white;
 }
 .categoriesinner ol > li div {
 left:45px;
 position:Relative;
}
.categoriesinner ol>li:after {
  content: '>';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  font-size: 15px;
  letter-spacing: 4px;
  margin-top: 5px;
  color: #D55E2D;
  text-align: center;
}

.categoriesinner ol>li:last-child:after {
  content: '|';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  font-size: 15px;
  letter-spacing: 4px;
  margin-top: 5px;
  color: #D55E2D;
  text-align: center;
}

.categoriesd {
  cursor:pointer;
}

.categoriesd:hover {
  color: #D55E2D;
}